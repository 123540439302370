import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './tabs.css';

function Tabs({ tabsData, classes, activeValue, onChange }) {
  return (
    <div className={classes.container}>
      {tabsData.map(({ label, to }, idx) => {
        const isActive = activeValue === (to || idx);
        const copyClassName = classNames(styles.tabCopy, classes.tabCopy, {
          [styles.tabsIndicator]: isActive,
          [classes.tabsIndicator]: isActive,
          [classes.activeCopy]: isActive,
          [styles.activeCopy]: isActive,
        });

        const button = (
          <button
            className={classNames(styles.tab, classes.tab)}
            key={idx}
            aria-selected={isActive}
            type='button'
            role='tab'
            onClick={() => onChange(idx)}
            data-testid={`${label.replace(/\W+/g, '-').toLowerCase()}-tab-button`}
          >
            <span className={copyClassName}>{label}</span>
          </button>
        );

        return to ? (
          <Link key={idx} to={to}>
            {button}
          </Link>
        ) : (
          button
        );
      })}
    </div>
  );
}

Tabs.defaultProps = {
  classes: {},
  onChange: () => {},
};

Tabs.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    tabCopy: PropTypes.string,
    tabsIndicator: PropTypes.string,
    activeCopy: PropTypes.string,
    tab: PropTypes.string,
  }),

  onChange: PropTypes.func,
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
    })
  ).isRequired,
  activeValue: PropTypes.node.isRequired,
};

export default Tabs;
