import { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../../informational/spinner';
import LegalBody from '../../typography/legal-body';

import Typography from '../../typography.module.css';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './text-field.css';

import close from './close.svg';
import lock from './lock.svg';
import search from './search.svg';

const icons = {
  search,
  close,
  lock,
};

/*
 * any props in rest must be able to spread into a native HTML input
 * only add new props to the props object below if it is not only passed into <input>
 */
function TextField({
  classes = {},
  label,
  onBlur,
  onFocus,
  isLabelHidden,
  isFocused: propsIsFocused,
  setIsFocused: propsSetIsFocused,
  propRef,
  inputRef,
  error: { errorMessage = '', hasError },
  endAdornment: {
    icon,
    isLoading = false,
    endContent,
    endCustom,
    onIconClick = () => {},
    iconClasses,
  },
  startAdornment: { startContent, startCustom },
  legalMessage,
  variant,
  canType,
  ...rest
}) {
  const {
    defaultValue,
    id,
    placeholder,
    readOnly: propsReadOnly,
    required,
    value,
  } = rest;
  const readOnly = propsReadOnly || !canType || icon === 'lock';

  const localOuterRef = useRef(null);
  const localInnerRef = useRef(null);
  const outerRef = propRef || localOuterRef;
  const innerRef = inputRef || localInnerRef;

  const shouldShowPlaceholder = !!useMemo(
    () => placeholder && label !== placeholder,
    [label, placeholder]
  );
  const variants = useMemo(
    () => ({
      isDefault: variant === 'default',
      isInBorder: variant === 'in-border',
    }),
    [variant]
  );

  const [isFocusedLocal, setIsFocusedLocal] = useState(false);
  const isFocused =
    typeof propsIsFocused === 'boolean' ? propsIsFocused : isFocusedLocal;

  function setIsFocused(input) {
    if (propsSetIsFocused) {
      propsSetIsFocused(input);
    } else {
      setIsFocusedLocal(input);
    }
  }

  function handleFocus(e) {
    onFocus(e);
    setIsFocused(true);
  }

  function handleBlur(e) {
    onBlur(e);
    setIsFocused(false);
  }

  const hasValue = !!value || defaultValue || !!innerRef.current?.value;
  const focusedStyle = { [styles.focused]: isFocused && !readOnly };

  const labelClassName = classNames(
    styles.label,
    classes.label,
    Typography.legalBody,
    Typography.bold,
    {
      [styles.inBorder]: variants.isInBorder,
      [styles.adornmentLabel]: startContent || startCustom,
      [styles.error]: errorMessage || hasError,
      [styles.placeholder]: !(isFocused || hasValue),
      [styles.hidden]: isLabelHidden && (hasValue || isFocused),
      [styles.readOnly]: readOnly,
      ...focusedStyle,
    }
  );
  const inputClassName = classNames(styles.input, classes.input, {
    [styles.inBorder]: variants.isInBorder,
    [styles.error]: errorMessage || hasError,
    [styles.readOnly]: readOnly,
    [styles.hasStartAdornment]: startCustom || startContent,
    [styles.showPlaceholder]:
      !variants.isInBorder && shouldShowPlaceholder && !(isFocused || hasValue),
    ...focusedStyle,
  });
  const borderClassName = classNames(styles.border, {
    [styles.readOnly]: readOnly,
    ...focusedStyle,
  });

  function handleLabelClick() {
    onFocus();
    innerRef.current.focus();
  }

  return (
    <div
      className={classNames(styles.container, classes.container, {
        [styles.unfocused]: !isFocused,
      })}
    >
      <div
        className={classNames(styles.fieldContainer, classes.fieldContainer)}
        ref={outerRef}
      >
        <label htmlFor={id} className={labelClassName} onClick={handleLabelClick}>
          {label || placeholder}
          {required ? <span className={styles.asterisk}>*</span> : null}
        </label>

        <div
          className={classNames(styles.inputContainer, classes.inputContainer, {
            [styles.errorBorder]: errorMessage || hasError,
            [styles.readOnly]: readOnly,
            ...focusedStyle,
          })}
        >
          {startContent || startCustom ? (
            <div
              className={classNames(styles.startAdornment, {
                [styles.startContent]: startContent,
              })}
            >
              {startContent || startCustom}
            </div>
          ) : null}

          <input
            {...rest}
            className={inputClassName}
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={innerRef}
            placeholder={placeholder || label}
            readOnly={readOnly}
          />

          {icons[icon] && !isLoading ? (
            <button
              type='button'
              className={classNames(
                styles.iconButton,
                styles[`icon-${icon}`],
                borderClassName,
                iconClasses,
                {
                  [styles.error]: hasError || errorMessage,
                  [styles.filledButton]: hasValue,
                }
              )}
              disabled={readOnly}
              onClick={onIconClick}
              aria-label={icon}
            >
              <img src={icons[icon]} alt='' />
            </button>
          ) : null}

          {isLoading ? (
            <div className={classNames(styles.spinner, borderClassName)}>
              <Spinner size={20} />
            </div>
          ) : null}

          {endContent || endCustom ? (
            <div
              className={classNames(styles.endAdornment, borderClassName, {
                [styles.error]: hasError || errorMessage,
                [styles.endContent]: endContent,
              })}
            >
              {endContent || endCustom}
            </div>
          ) : null}
        </div>

        {legalMessage}

        {errorMessage ? (
          <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
        ) : null}
      </div>
    </div>
  );
}

TextField.defaultProps = {
  autoComplete: 'off',
  autoFocus: false,
  maxLength: '',
  canType: true,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyUp: () => {},
  type: 'text',
  classes: {},
  isLabelHidden: false,
  variant: 'default',
  error: {},
  endAdornment: {},
  startAdornment: {},
};

export const TextFieldPropTypes = {
  classes: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
    container: PropTypes.string,
    fieldContainer: PropTypes.string,
    inputContainer: PropTypes.string,
  }),
  /** A readOnly proxy that doesn't come with native readOnly styling & ADA */
  canType: PropTypes.bool,
  /** A label that covers the placeholder and moves up above the element when focused / having a value */
  label: PropTypes.node,
  /** trigger functionality when clicking away from a focused element */
  onBlur: PropTypes.func,
  /** trigger functionality when focusing into an element */
  onFocus: PropTypes.func,
  /** hide the label */
  isLabelHidden: PropTypes.bool,
  /** preset variants if you dont want to play with too many props */
  variant: PropTypes.oneOf(['default', 'in-border']),
  /** if you want to pass in a ref object to refer to outside of the component */
  propRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  /** if you want to pass in a ref object to refer to outside of the component */
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  /** if you want to control focus state from outside the component */
  isFocused: PropTypes.bool,
  /** if you want to control focus state from outside the component */
  setIsFocused: PropTypes.func,
  /** error handling - errorMessage if we want to see a specific error, hasError if you just want error styling */
  error: PropTypes.shape({
    errorMessage: PropTypes.node,
    hasError: PropTypes.bool,
  }),
  /** for adding content inline at the end of the input */
  endAdornment: PropTypes.shape({
    icon: PropTypes.node,
    isLoading: PropTypes.bool,
    endContent: PropTypes.node,
    endCustom: PropTypes.node,
    onIconClick: PropTypes.func,
    iconClasses: PropTypes.string,
  }),
  /** for adding content inline at the beginning of the input */
  startAdornment: PropTypes.shape({
    startContent: PropTypes.node,
    startCustom: PropTypes.node,
  }),
  /** for adding any additional language beneath the field, but above error messages */
  legalMessage: PropTypes.node,

  // spread via ...rest
  /** will enable browser behavior, useful for email / name fields */
  autoComplete: PropTypes.oneOf(['off', 'on']),
  /** react property for focusing elements */
  autoFocus: PropTypes.bool,
  /** native html property for specifying number of characters an input can have */
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** necessary for updating the value in a controlled input*/
  onChange: PropTypes.func,
  /** similar to onChange, but useful for formatting values of the field after making the change (e.g. adding to a date field) */
  onKeyUp: PropTypes.func,
  /** native html property that references the element */
  name: PropTypes.string,
  /** property that enables cypress testing */
  'data-testid': PropTypes.string,
  /** necessary for semantic html */
  id: PropTypes.string,
  /** necessary for rendering value in a controlled input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** useful for specifying the value in an uncontrolled input when the component loads */
  defaultValue: PropTypes.string,
  /** value that will show up explicitly in the input box */
  placeholder: PropTypes.string,
  /** native html property for enforcing completeness, but will also append a red asterisk */
  required: PropTypes.bool,
  /** native html property for preventing edits to the field */
  readOnly: PropTypes.bool,
  /** native html property on inputs that triggers extra behavior / special keyboards on mobile devices. While there are more [input types](https://www.w3schools.com/html/html_form_input_types.asp) available generally, only these 3 should be used with TextField */
  type: PropTypes.oneOf(['email', 'text', 'password']),
};

TextField.propTypes = TextFieldPropTypes;

export default TextField;
