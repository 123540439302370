import { useQuery } from 'react-query';

import { analyticsIdentify, setTrackedCustomProps } from '@pumpkincare/analytics';
import { getIsLoggedIn } from '@pumpkincare/shared';

import { getUserSelf } from './user-service';
import { transformUser } from './user-utils';

export const USER_SELF_QUERY = 'userSelf';

export const getUserBillingAddress = user => user.billingAddress;
export const getUserEmail = user => user.email;
export const getUserExternalId = user => user.externalId;
export const getUserFirstName = user => user.firstName;
export const getUserId = user => user.id;
export const getUserInvoices = user => user.invoices;
export const getUserIsChargedAnnually = user => user.isChargedAnnually;
export const getUserLapsedSince = user => user.lapsedSince;
export const getUserLastName = user => user.lastName;
export const getUserMonthlyChargeDay = user => user.monthlyChargeDay;
export const getUserNextChargeDate = user => user.nextChargeDate;
export const getUserPets = user => user.pets;
export const getUserPhone = user => user.phone;
export const getUserPlans = user => user.plans;
export const getUserPolicies = user => user.policies;
export const getUserShippingAddress = user => user.shippingAddress;
export const getUserShippingAddressZipCode = user => user.shippingAddress.zipcode;
export const getUserTransactionFee = user => user.transactionFee;

export function getUserQueryFn() {
  return getUserSelf() // "users" is actually just a user
    .then(({ users: user, invoices }) => transformUser(user, invoices))
    .then(user => {
      setTrackedCustomProps({
        userId: user.id,
        userPets: user.pets,
        email: user.email,
      });
      analyticsIdentify(user, user.id);

      return user;
    });
}

export function useUssr(select) {
  return useQuery(
    [USER_SELF_QUERY],

    getUserQueryFn,

    {
      cacheTime: Infinity,
      enabled: getIsLoggedIn(),

      placeholderData: {
        billingAddress: {
          city: '',
          firstName: '',
          id: '',
          lastName: '',
          stateProvince: '',
          street1: '',
          street2: '',
          userId: '',
          zipcode: '',
        },

        email: '',
        externalId: '',
        firstName: '',
        id: '',
        invoices: [],
        isChargedAnnually: false,
        lapsedSince: null,
        lastName: '',
        monthlyChargeDay: null,
        nextChargeDate: null,
        pets: [],
        phone: '',
        plans: [],
        policies: [],

        shippingAddress: {
          city: '',
          firstName: '',
          id: '',
          lastName: '',
          stateProvince: '',
          street1: '',
          street2: '',
          userId: '',
          zipcode: '',
        },

        transactionFee: 0,
      },

      select,
    }
  );
}
