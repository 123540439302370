import styles from './svg-icon.module.css';

function SvgIcon({ children, className, color, size = 24, ...rest }) {
  return (
    <svg
      fill={color}
      width={size}
      height={size}
      focusable='false'
      viewBox={`0 0 ${size} ${size}`}
      className={`${styles.root} ${className}`}
      {...rest}
    >
      {children}
    </svg>
  );
}

export default SvgIcon;
