import { Auth } from 'aws-amplify';
import axios from 'axios';

import { POLICY_BASE_API } from '@pumpkincare/config';

import { transformPetRequest, transformQuote } from './utils/quote-utils';

// this is needed to prefetch quote, we return already transformed response
export function getQuoteById({ quoteId, signal }) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}`;

  return axios.get(url, { signal }).then(returnResponseBody).then(transformQuote);
}

export function patchQuote({ quoteId, zipCode, pets, vet }) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}`;

  return axios
    .patch(url, {
      policy_zipcode: zipCode,
      quote_pets: pets.map(transformPetRequest),
      vet,
    })
    .then(returnResponseBody);
}

export function getQuotePricingById({ quoteId, annualPay }) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/pricing${
    annualPay ? '?annual_pay=true' : ''
  }`;

  return axios.get(url).then(returnResponseBody);
}

export function fetchQuoteById(quoteId) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}`;

  return axios.get(url).then(returnResponseBody);
}

export function fetchQuoteWithFeeById({ quoteId }) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/transaction-fee`;

  return axios.post(url, {}).then(returnResponseBody);
}

export function fetchQuote(trackingId, identityId, isLoggedIn) {
  const url = `${POLICY_BASE_API}/quotes-v3`;

  function postQuote(headers) {
    return axios
      .post(
        url,
        { tracking_id: trackingId, identity_id: !identityId ? null : identityId },
        headers
      )
      .then(response => response.data.body);
  }

  return isLoggedIn
    ? Auth.currentAuthenticatedUser().then(response => {
        const headers = {
          Authorization: `Bearer ${response.signInUserSession.idToken.jwtToken}`,
        };

        return postQuote({ headers });
      })
    : postQuote();
}

export function addPetToQuote({ quoteId, pet, noopPayload }) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/pets`;

  return axios
    .post(url, {
      pet_name: pet.petName,
      species: pet.petBreedSpecies,
      ...noopPayload,
    })
    .then(returnResponseBody);
}

export function checkQuoteForExistingDuplicates({ quoteId, isLoggedIn }) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/check-for-existing-duplicate-pets`;

  function quoteDuplicateCheck(headers) {
    return axios.post(url, {}, headers).then(response => response.data.body);
  }

  return isLoggedIn
    ? Auth.currentAuthenticatedUser().then(response => {
        const headers = {
          Authorization: `Bearer ${response.signInUserSession.idToken.jwtToken}`,
        };

        return quoteDuplicateCheck({ headers });
      })
    : Promise.all([]);
}

export function deletePetFromQuote({ quoteId, quotePetId, noopPayload }) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/pets/${quotePetId}`;

  return axios.delete(url, { data: noopPayload }).then(returnResponseBody);
}

export function patchQuotePet({ quoteId, pet, noopPayload }) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/pets/${pet.id}`;

  return axios
    .patch(url, {
      pet_name: pet.petName,
      species: pet.petBreedSpecies,
      pet_gender: pet.petGender,
      pet_age: pet.petAge,
      breed_code: pet.petBreedCode,
      breed_name: pet.petBreedName,
      breed_type: pet.petBreedType,
      ...noopPayload,
    })
    .then(returnResponseBody);
}

export function registerQuote(params) {
  const {
    quoteId,
    activeId,
    trackingId,
    firstName,
    lastName,
    email,
    policyZipCode,
    discountId,
    noopPayload,
  } = params;
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/register-quote`;

  return axios
    .post(url, {
      quote_pet_id: activeId,
      tracking_id: trackingId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      policy_zipcode: policyZipCode,
      discount_id: discountId || undefined,
      ...noopPayload,
    })
    .then(returnResponseBody);
}

export function putQuotePet({ quoteId, pet, noopPayload }) {
  const { deductible, copay, annual_limit, plan, preventativeSelected } = pet;
  const url = `${POLICY_BASE_API}/quotes/multipet/${quoteId}/pets/${pet.id}`;

  return axios.put(url, {
    deductible,
    copay,
    annual_limit,
    plan_id: preventativeSelected ? plan.id : null,
    ...noopPayload,
  });
}

export function putQuotePetPolicy({ quoteId, pet }) {
  const { deductible, annual_limit } = pet;
  const url = `${POLICY_BASE_API}/quotes/multipet/${quoteId}/pets/${pet.id}`;

  return axios.put(url, { deductible, annual_limit });
}

export function getPetPolicy(quoteId, petId) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/pets/${petId}/policies`;

  return axios.get(url).then(returnResponseBody);
}

export function deleteQuotePet({ quoteId, quotePetId, noopPayload }) {
  const url = `${POLICY_BASE_API}/quotes/multipet/${quoteId}/pets/${quotePetId}`;

  return axios.delete(url, { data: noopPayload });
}

export function finalizeQuote(params) {
  const { quoteId, postBody } = params;
  const url = `${POLICY_BASE_API}/quotes/multipet/${quoteId}/finalize`;

  return axios.post(url, postBody).then(returnResponseBody);
}

export function patchQuoteVetAttribution(quoteId, payload) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/vet`;

  return axios.patch(url, payload).then(returnResponseBody);
}

export function putQuotePetPlan(quoteId, pet, plan) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/pets/${pet.id}/plan`;

  return axios.put(url, {
    plan: { id: plan.id },
  });
}

export function deleteQuotePetPlan(quoteId, pet) {
  const url = `${POLICY_BASE_API}/quotes-v3/${quoteId}/pets/${pet.id}/plan`;

  return axios.delete(url);
}

export function getDiscounts(quoteId) {
  const url = `${POLICY_BASE_API}/quotes/multipet/${quoteId}/getdiscounts`;

  return axios.get(url).then(returnResponseBody);
}

export const QuotesService = {
  fetchQuote,
  fetchQuoteById,
  fetchQuoteWithFeeById,
  addPetToQuote,
  checkQuoteForExistingDuplicates,
  deletePetFromQuote,
  patchQuotePet,
  registerQuote,
  putQuotePet,
  patchQuoteVetAttribution,
};

function returnResponseBody(response) {
  return response.data.body;
}
