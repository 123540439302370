import axios from 'axios';

import {
  BREED_DEFINITIONS_API,
  CLOUDFLARE_WORKER_API,
  PROGRAM_RESOLVER_API,
  STATES_API,
} from '@pumpkincare/config';

import { validateState } from './utils/validation-utils';

export function checkProductsByState(stateCode) {
  const url = `${STATES_API}/${stateCode}`;

  return axios.get(url).then(response => response.data);
}

export function getEmbarkDiscount(stateCode) {
  const url = `${CLOUDFLARE_WORKER_API}/embark-state-discount/${stateCode}`;

  return axios.get(url).then(response => response.data);
}

export function requestZipCodeValidity(zipCode) {
  const url = `${CLOUDFLARE_WORKER_API}/zipcodes/${zipCode}`;

  return axios
    .get(url)
    .then(response => response.data)
    .then(responseData => {
      const { state } = responseData;
      const valid = validateState(state);

      return { state, valid };
    });
}

export function getBreedDefinition(breedDefinition, petName, state) {
  return axios
    .get(
      `${BREED_DEFINITIONS_API}/breed-definitions/${breedDefinition}?petName=${petName}&state=${state}`
    )
    .then(response => response.data);
}

export function getProgramResolver(usState, species, age) {
  const url = `${PROGRAM_RESOLVER_API}/${usState}/${species}/${age}`;

  return axios.get(url).then(response => response.data);
}
