import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Confetti, LegalBody, Radio, RadioGroup } from '@pumpkincare/shared/ui';

import VetClinic from '../vet-clinic';

import styles from './vet-attribution.css';

const NO_RESULTS_TITLE_HIDE_FORM = "I don't see my vet clinic on this list";

function VetAttribution(props) {
  const {
    disabled,
    isPreselect,
    message,
    onVetClinicChange,
    onHasVetChange,
    required,
    selectedVet,
    variant,
    zipCode,
  } = props;

  const [hasVet, setHasVet] = useState(
    isPreselect ? (Object.keys(selectedVet).length > 0 ? 'yes' : 'no') : ''
  );
  const [isConfettiVisible, setIsConfettiVisible] = useState(false);

  const variants = {
    isDefault: variant === 'default',
    isBordered: variant === 'bordered',
  };

  useEffect(() => {
    if (isPreselect) {
      setHasVet(Object.keys(selectedVet).length > 0 ? 'yes' : 'no');
    }
  }, [isPreselect, selectedVet]);

  function handleVetClinicChange(payload) {
    if (
      payload.existing?.vet_name &&
      payload.existing.vet_name !== NO_RESULTS_TITLE_HIDE_FORM
    ) {
      onVetClinicChange?.(payload.existing);
      setIsConfettiVisible(true);
    } else {
      onVetClinicChange?.({});
      setIsConfettiVisible(false);
    }
  }

  function handleRadioChange(value) {
    setHasVet(value);

    if (value === 'yes') {
      onHasVetChange?.(true);
    } else if (value === 'no') {
      onHasVetChange?.(false);
      setIsConfettiVisible(false);
    }
  }

  const formClassName = classNames(styles.formContainer, {
    [styles.showForm]: hasVet === 'yes',
  });

  const labelClassName = classNames({
    [styles.label]: variants.isDefault,
    [styles.labelBordered]: variants.isBordered,
  });

  const wrapperRadioClass = classNames({
    [styles.wrapperBordered]: variants.isBordered,
    [styles.wrapperRadio]: !variants.isBordered,
  });

  const radioClassName = classNames({
    [styles.radioBordered]: variants.isBordered,
    [styles.radio]: !variants.isBordered,
  });

  return (
    <>
      <LegalBody className={labelClassName}>
        Do you have a primary vet?
        {required ? <span className={styles.required}>*</span> : null}
      </LegalBody>

      {message}

      <div className={wrapperRadioClass}>
        <div className={styles.radioContainer}>
          <RadioGroup
            disabled={disabled}
            onChange={handleRadioChange}
            value={hasVet}
            row
          >
            <Radio
              value={'yes'}
              label='Yes'
              classes={{ root: radioClassName, label: styles.radioLabel }}
              data-testid='primary-vet-yes'
              small
            />
            <Radio
              value={'no'}
              label='No'
              classes={{ root: styles.radio, label: styles.radioLabel }}
              data-testid='primary-vet-no'
              small
            />
          </RadioGroup>
        </div>
      </div>

      <div className={formClassName}>
        <VetClinic
          disabled={disabled || hasVet === ''}
          canShowForm={false}
          onChange={handleVetClinicChange}
          selectedVet={selectedVet}
          label={'Type to search for your vet hospital'}
          zipCode={zipCode}
        />
      </div>

      {isConfettiVisible ? (
        <LegalBody className={styles.vetAccepted}>
          🎉 Pumpkin covers care from your vet!
          <br />
          In fact, we cover care from any licensed vet in the US or CAN.
        </LegalBody>
      ) : null}

      {hasVet === 'no' ? (
        <div className={styles.vetAccepted}>
          🌟 Not to worry!
          <br />
          When the time comes, we cover care from any licensed vet in the US or CAN.
        </div>
      ) : null}

      {isConfettiVisible ? <Confetti /> : null}
    </>
  );
}

VetAttribution.defaultProps = {
  disabled: false,
  isPreselect: false,
  message: null,
  required: true,
  selectedVet: {},
  variant: 'default',
  zipCode: '',
};

VetAttribution.propTypes = {
  disabled: PropTypes.bool,
  isPreselect: PropTypes.bool,
  message: PropTypes.node,
  onVetClinicChange: PropTypes.func,
  onHasVetChange: PropTypes.func,
  required: PropTypes.bool,
  selectedVet: PropTypes.object,
  variant: PropTypes.string,
  zipCode: PropTypes.string,
};

export default VetAttribution;
